export const PAGE_SIZE_OPTIONS = [30, 50, 100];
export const FILE_SIZE_MAX = 52428800;
export const MIN_DATE = new Date(2000, 0, 1);

export interface ISideNav {
  name: string;
  visibility: boolean;
  hasCustomIcon?: boolean;
  customIconClass?: string;
  icon?: string;
  link: string;
}

export function API_URL_CONFIG(baseUrl: string) {
  const BASE_API_URL = `${baseUrl}/api/v2`;
  return {
    AUTH: {
      POST: {
        LOGIN: BASE_API_URL + '/login',
        CONFIRM: BASE_API_URL + '/confirm',
        RESEND_PIN: BASE_API_URL + '/resend-pin',
        SET_PASSWORD: BASE_API_URL + '/setPassword',
      },
      GET: {
        CURRENT: BASE_API_URL + '/current',
        LOGOUT: BASE_API_URL + '/logout',
      },
    },
    CUSTOMER: {
      GET: {
        CUSTOMERS: BASE_API_URL + '/customers',
        COMBINED_HEADER_DATA: BASE_API_URL + '/customers/combined-header-data',
      },
    },
    USER: {
      GET: {
        USER: BASE_API_URL + '/users/', // {id}
        STATIC_DATA: BASE_API_URL + '/users/staticData',
        TALQ_FILTERS: BASE_API_URL + '/users/talq/staticData/filters',
        COMMENTS: BASE_API_URL + '/users/comments',
        COMMENTS_UNREAD: BASE_API_URL + '/users/unread-comments/',
        COMMENTS_UNREAD_COUNT: BASE_API_URL + '/users/count-unread-comments/',
        COMMENTS_WITH_PHOTO: BASE_API_URL + '/users/comments-with-photos',
        NOTES: BASE_API_URL + '/users/notes/',
      },
      POST: {
        NEW: BASE_API_URL + '/users/new',
        CHANGE_CUSTOMER: BASE_API_URL + '/users/changeCustomer/',
        SW_TOKEN: BASE_API_URL + '/users/service-workers',
      },
    },
    BATCH: {
      GET: {
        BATCH: BASE_API_URL + '/batch',
        ACKNOWLEDGED: BASE_API_URL + '/batch/acknowledged',
      },
      PUT: {
        BATCH: BASE_API_URL + '/batch/changeStatus',
      },
    },
    ALERTS: {
      GET: {
        ALERTS: BASE_API_URL + '/alerts/',
        ACTIVE: BASE_API_URL + '/alerts/active/',
        GROUP: BASE_API_URL + '/alerts/group',
        NEW: BASE_API_URL + '/alerts/new',
        COUNT: BASE_API_URL + '/alerts/newCount',
      },
      PUT: {
        ALERTS: BASE_API_URL + '/alerts/updateStatus',
      },
    },
    REPORTS: {
      GET: {
        REPORT: BASE_API_URL + '/report',
        FORM_DATA: BASE_API_URL + '/report/form-data',
        SETTINGS: BASE_API_URL + '/report/settings',
        SETTINGS_TYPES: BASE_API_URL + '/report/settings/types',
      },
    },
    EXPORT: {
      GET: {
        EXPORT_DATA: BASE_API_URL + '/export/form-data',
        EXPORT_EMAIL: BASE_API_URL + '/export/email-report',
        EXPORT_REPORT: BASE_API_URL + '/export/report',
        AUTO_REPORT: BASE_API_URL + '/export/auto-report/',
      },
    },
    FILES: {
      GET: {
        FILES: BASE_API_URL + '/files',
        FILES_OLD: BASE_API_URL + '/files/documents',
        AVATAR: BASE_API_URL + '/files/avatar/download',
      },
    },
    UNITS: {
      GET: {
        AUTOMATIC_MODE: `${BASE_API_URL}`,
        DEVICES: `${BASE_API_URL}/devices/`,
        TALQ_DEVICES: `${BASE_API_URL}/talq/devices/`,
        TALQ: `${BASE_API_URL}/talq/`,
        TALQ_SUMMARY: `${BASE_API_URL}/talq/devices/graph`,
        RELAY_TYPES: `${BASE_API_URL}/devices/relay-types`,
        DEVICES_SHORT: `${BASE_API_URL}/devices/short-list`,
        COMMANDS_SETTINGS: (
          deviceId: string | number,
          relayId: string | number,
          type: string,
        ) =>
          `${BASE_API_URL}/devices/${deviceId}/${relayId}/modeSettings?type=${type}`,
        COMMANDS_SETTINGS_MODE: (
          deviceId: string | number,
          relayId: string | number,
        ) => `${BASE_API_URL}/devices/${deviceId}/${relayId}/mode`,
        LUX_LEVELS: (deviceId: number) =>
          `${BASE_API_URL}/devices/${deviceId}/luxlevels`,
        DALI_LIST: (deviceId: number) =>
          `${BASE_API_URL}/devices/${deviceId}/dali-list`,
        DALI: (deviceId: number, daliId: number) =>
          `${BASE_API_URL}/devices/${deviceId}/${daliId}`,
        SERVICE_VIEW: (deviceId: number) =>
          `${BASE_API_URL}/devices/${deviceId}/service-view`,
        COMMANDS_STATIC_DATA: `${BASE_API_URL}/devices/staticMode`,
        COMMANDS_DEVICE_STATIC_DATA: (deviceId: number) =>
          `${BASE_API_URL}/devices/${deviceId}/staticMode`,
      },
      PUT: {
        DEVICES: `${BASE_API_URL}/devices/`,
        DEVICE_COMMANDS_SETTINGS_MODE: (deviceId: string | number) =>
          `${BASE_API_URL}/devices/${deviceId}/mode`,
        DEVICE_COMMANDS_SETTINGS_MODE_GROUP: (
          deviceTypes: string = '',
          filters: string = '',
          deviceStatuses: string = '',
          search: string = '',
        ) =>
          `${BASE_API_URL}/devices/group?deviceType=${deviceTypes}&filter=${filters}&search=${encodeURIComponent(
            search,
          )}&deviceStatuses=${deviceStatuses}`,
        DEVICE_COMMANDS_SETTINGS: (deviceId: string | number, type: string) =>
          `${BASE_API_URL}/devices/${deviceId}/modeSettings?type=${type}`,
        DEVICE_GROUP_MODE: (
          type: string,
          deviceTypes: string = '',
          filters: string = '',
          deviceStatuses: string = '',
          search: string = '',
        ) =>
          `${BASE_API_URL}/devices/groupMode?type=${type}&deviceType=${deviceTypes}&filter=${filters}&search=${encodeURIComponent(
            search,
          )}&deviceStatuses=${deviceStatuses}`,
      },
      PATCH: {
        COMMANDS_REFRESH: (
          deviceId: string | number,
          relayId: string | number,
        ) => `${BASE_API_URL}/devices/${deviceId}/${relayId}/refresh`,
      },
      POST: {
        ADD_COMMAND: `${BASE_API_URL}/talq/devices/commands/`,
      },
    },
    TAG: {
      GET: BASE_API_URL + '/tag',
    },
    GRAPHS: {
      GET: BASE_API_URL + '/graphs',
    },
    GROUPS: {
      GET: BASE_API_URL + '/groups',
      POST: BASE_API_URL + '/groups/',
      PUT: BASE_API_URL + '/groups/',
      DELETE: BASE_API_URL + '/groups/',
    },
    ELECTRICITY_PRICE: {
      GET: {
        DATA: BASE_API_URL + '/electricity-price/data',
        LIMITS: BASE_API_URL + '/electricity-price/limits',
      },
      POST: {
        UPDATE: BASE_API_URL + '/electricity-price/update',
        LIMITS: BASE_API_URL + '/electricity-price/limits',
      },
    },
    REGULAR_INSPECTION: {
      GET: {
        PLANNED: BASE_API_URL + '/regular-inspection/planned',
        DONE: BASE_API_URL + '/regular-inspection/done',
        TYPES: BASE_API_URL + '/regular-inspection/types',
        REPORT: BASE_API_URL + '/regular-inspection/report',
        OUTDATE: BASE_API_URL + '/regular-inspection/outdated',
        DEVICE: BASE_API_URL + '/regular-inspection/device',
      },
      POST: {
        ADD: BASE_API_URL + '/regular-inspection',
      },
    },
    DASHBOARD: {
      GET: {
        LAYOUT: BASE_API_URL + '/dashboard/layout',
        SETTINGS: BASE_API_URL + '/dashboard/settings',
        WIDGETS: BASE_API_URL + '/dashboard/widgets',
        SETTINGS_TYPES: BASE_API_URL + '/dashboard/settings/types',
      },
      POST: {
        LAYOUT: BASE_API_URL + '/dashboard/layout',
        SETTINGS: BASE_API_URL + '/dashboard/settings',
      },
      PATCH: {
        WIDGETS: BASE_API_URL + '/dashboard/settings/widget',
      },
    },
    SCHEDULER_MANAGER: {
      GET: {
        CALENDARS: BASE_API_URL + '/talq/calendars/',
        PROFILES: BASE_API_URL + '/talq/control-programs/',
        PROFILES_CONTROLS: BASE_API_URL + '/talq/control-programs/',
      },
      POST: {
        CALENDARS: BASE_API_URL + '/talq/calendars/',
        PROFILES: BASE_API_URL + '/talq/control-programs/',
      },
      PUT: {
        CALENDARS: BASE_API_URL + '/talq/calendars/',
        PROFILES: BASE_API_URL + '/talq/control-programs/',
      },
      DELETE: {
        CALENDARS: BASE_API_URL + '/talq/calendars/',
        PROFILES: BASE_API_URL + '/talq/control-programs/',
      },
    },
    DYNAMIC_CONTROLS: {
      GET: {
        DYNAMIC_CONTROL: BASE_API_URL + '/dynamic-control/devices/',
        ALLOWED_LEVELS: BASE_API_URL + '/dynamic-rules/levels/',
        DYNAMIC_RULES: BASE_API_URL + '/dynamic-rules/',
        DYNAMIC_TRIGGERS: BASE_API_URL + '/dynamic-triggers',
        HOLD_TIME_UNITS: BASE_API_URL + '/dynamic-rules/static/',
        DASHBOARD: BASE_API_URL + '/dynamic-rules/dashboard/',
        LIGHTING_MODELS: BASE_API_URL + '/lighting-models/',
        FULL_DATA: BASE_API_URL + '/dynamic-rules/full-data/',
      },
      POST: {
        DYNAMIC_RULES: BASE_API_URL + '/dynamic-rules/',
        DYNAMIC_TRIGGERS: BASE_API_URL + '/dynamic-triggers/',
        LIGHTING_MODELS: BASE_API_URL + '/lighting-models/',
        RULE_LIST_DEVICES: BASE_API_URL + '/dynamic-rules/',
      },
      PUT: {
        ALLOWED_LEVELS: BASE_API_URL + '/dynamic-rules/levels/',
        DYNAMIC_RULES: BASE_API_URL + '/dynamic-rules/',
        RULE_STATE: BASE_API_URL + '/dynamic-rules/',
        LIGHTING_MODELS: BASE_API_URL + '/lighting-models/',
      },
      DELETE: {
        DYNAMIC_RULES: BASE_API_URL + '/dynamic-rules/',
        RULE_LIST_DEVICES: BASE_API_URL + '/dynamic-rules/',
        DYNAMIC_TRIGGERS: BASE_API_URL + '/dynamic-triggers/',
        LIGHTING_MODELS: BASE_API_URL + '/lighting-models/',
      },
      PATCH: {
        DYNAMIC_CONTROL: BASE_API_URL + '/dynamic-control/devices/',
      },
    },
    WEATHER_WIDGET: {
      GET: {
        DATA: BASE_API_URL + '/weather-widget/data',
        HISTORY_DATA: BASE_API_URL + '/weather-widget/history-data',
        WARNING_SHORT: BASE_API_URL + '/weather-widget/warning-short',
        WARNINGS: BASE_API_URL + '/weather-widget/warnings',
        LIMITS: BASE_API_URL + '/weather-widget/limits',
      },
      POST: {
        DEFINE_STATION: BASE_API_URL + '/weather-widget/define-station',
        LIMITS: BASE_API_URL + '/weather-widget/limits',
      },
      DELETE: {
        LIMITS: BASE_API_URL + '/weather-widget/limits/',
      },
    },
  };
}

export const WEATHER_API = {
  apiKey: 'b28017daa0a128a10f082ad245ed4956',
  apiUrl: 'https://api.openweathermap.org/data/2.5/forecast',
};

export const PATH_ROUTES_HASH = {
  home: '',
  login: 'login',
  loginMobile: 'mobile/login',
  mapMobile: 'mobile/map',
  dashboard: 'dashboard',
};

export const ROLES: any = {
  ROOT_ADMIN: 'role_root',
  CUSTOMER_ADMIN: 'role_customer_admin',
  CONTROL_ENGINEER: 'control_engineer',
  SERVICE_TECHNICIAN: 'servicetechnician',
  VIEWER: 'role_view',
  EXTERNAL_USER: 'role_external_user',
};

export const DEFAULT_CALENDAR_OPTIONS = {
  headerToolbar: {
    left: 'prev,next',
    center: 'title',
    right: null,
  },
  editable: true,
  timeZone: 'local',
  height: 'auto',
  droppable: false,
  selectable: true,
  firstDay: 1,
  initialView: 'dayGridMonth',
};

export enum Apps {
  SMARTLIGHT = 'smartlight',
  STREETLIGHT = 'streetlight',
  WATERPUMPS = 'waterpumps',
}
