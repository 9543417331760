import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as L from 'leaflet';
import * as _ from 'lodash';

import { MapComponent } from '@nx-workspace/components/map';
import { RxStompService } from '../../rx-stomp.service';
import { IUnit } from '../../interfaces';
import { PopupBasicComponent } from '../../components/dialogs/popup-basic/popup-basic.component';
import { PreloaderService } from '../../services/preloader.service';
import { UnitsService } from '../../services/units.service';

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss'],
})
export class MapViewComponent implements OnInit {
  markers: any;
  unit: any;
  private unsubscribe = new Subject();
  @ViewChild('map') map!: MapComponent;

  constructor(
    public changeDetectorRefs: ChangeDetectorRef,
    private dialog: MatDialog,
    private unitsService: UnitsService,
    private preloaderService: PreloaderService,
    private rxStompService: RxStompService,
  ) {}

  ngOnInit(): void {
    this.getUnits();
    this.rxStompService.watch('/topic/devices').subscribe((message: any) => {
      const updatedDevice = JSON.parse(message.body);
      updatedDevice.color = '#' + updatedDevice.color;
      this.markers[
        _.findIndex(this.markers, (item: IUnit) => item.id === updatedDevice.id)
      ] = updatedDevice;
      this.map.setMarkers(this.markers);
      console.log(updatedDevice);
    });
  }

  getUnits() {
    this.preloaderService.show();
    this.unitsService
      .getUnits()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((resp: any) => {
        this.markers = resp;
        this.markers.forEach((item: IUnit) => (item.color = '#' + item.color));
        this.preloaderService.hide();
      });
  }

  openPopup(device: any) {
    this.unit = device;
    const deviceLocation = L.latLng(device.latitude, device.longitude);
    if (
      _.isNil(this.map.userLocation) ||
      this.map.userLocation!.distanceTo(deviceLocation) > 300
    ) {
      if (_.isNil(this.map.userLocation)) {
        this.map.showAlertPopup();
      }
      return;
    }
    const dialogRef = this.dialog.open(PopupBasicComponent, {
      width: '96%',
      height: 'auto',
      data: {
        device: this.unit,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getUnits();
      this.preloaderService.hide();
    });
  }
}
