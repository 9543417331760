import { Component } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss'],
  standalone: false,
})
export class CaptchaComponent {
  isSuccess = false;
  isWrong = false;

  constructor(private recaptchaV3Service: ReCaptchaV3Service) {}

  executeImportantAction(): void {
    this.recaptchaV3Service.execute('importantAction').subscribe((token) => {
      console.log('CAPTCHA TOKEN: ', token);
      if (token) {
        this.isSuccess = true;
      } else {
        this.isWrong = true;
      }
    });
  }

  reload() {
    window.location.reload();
  }
}
