<div class="header-block">
  <button mat-icon-button class="btn btn-close" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>

  <h2 mat-dialog-title *ngIf="data.title">{{ data.title }}</h2>
</div>

<mat-dialog-content>
  <p>{{ data.text }}</p>
  <mat-form-field *ngIf="data.input" class="input-wrapper">
    <mat-label>{{ data.input }}</mat-label>
    <input matInput [(ngModel)]="input" />
  </mat-form-field>
</mat-dialog-content>

<div class="form-btns">
  <button
    *ngIf="!data.hideCancelButton"
    mat-stroked-button
    color="primary"
    class="btn"
    mat-dialog-close
  >
    {{ 'GLOBAL.CANCEL' | translate }}
  </button>
  <button
    mat-flat-button
    [color]="data?.class?.indexOf('accept-button') > -1 ? 'primary' : 'warn'"
    class="btn"
    (click)="lockUser()"
    [disabled]="data.input && !input?.trim()"
  >
    {{ data.button }}
  </button>
</div>
