import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import {
  SettingsTabsForSingleTalqDevice,
  SettingsTabsForSingleUnit,
} from '@nx-workspace/enums/settings-tabs';
import { appCheck, wrapString } from '@nx-workspace/helpers/shared-functions';
import { ITag, ITalq, IUnit } from '@nx-workspace/interfaces';
import { AuthStorageService } from '@nx-workspace/services/auth-storage';
import { DevicesType } from '@nx-workspace/services/units';

import * as L from 'leaflet';
import 'leaflet.gridlayer.googlemutant';
import * as _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject } from 'rxjs';
import '../../../../../node_modules/leaflet.fullscreen/Control.FullScreen.js';

import * as Controls from './controls-options';
import * as WaterpumpsAssets from '@nx-workspace/static-data/waterpumps/map-assets';
import * as SmartlightAssets from '@nx-workspace/static-data/smartlight/map-assets';
import * as StreetlightAssets from '@nx-workspace/static-data/streetlight/map-assets';
import { APP_CONFIG, TABS_CONFIG } from '@nx-workspace/static-data/options';
import {
  GroupStatus,
  IIcon,
} from '@nx-workspace/static-data/smartlight/map-assets';
import { Apps } from '@nx-workspace/globals';
import { DialogConfirmComponent } from '@nx-workspace/dialogs/dialog-confirm';
import { MatDialog } from '@angular/material/dialog';

interface LassoHandlerFinishedEventData {
  latLngs: L.LatLng[];
  layers: L.Layer[];
}

interface LassoHandlerOptions {
  polygon?: L.PolylineOptions;
  intersect?: boolean;
}

export enum DeviceStatus {
  removed = 'removed',
  new = 'new',
  inGroup = 'inGroup',
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  standalone: false,
})
export class MapComponent implements OnInit, OnChanges {
  @ViewChild('mapElementRef', { static: false }) mapElementRef: ElementRef;

  @Input() markers!: IUnit[] & ITalq[];
  @Input() isLinksDisplayed = false;
  @Input() isExternalComponent = false;
  @Input() devicesType: DevicesType = DevicesType.c2;
  @Input() useMarkerCluster = false;
  @Input() isSettingsTabsVisible = false;
  @Input() isLassoButtonVisible = false;
  @Input() isLassoButtonDisabled = false;
  @Input() isUnitsStatusVisible = false;
  @Input() isRefreshButtonVisible = false;
  @Input() isChannelsButtonVisible = false;
  @Input() isFullscreenButtonVisible = true;
  @Output() markerClicked = new EventEmitter();
  // @Output() popupLinkClicked  = new EventEmitter();
  @Output() refreshButtonClicked = new EventEmitter();
  @Output() channelsButtonClicked = new EventEmitter();
  @Output() lassoSelectionFinished = new EventEmitter();
  @Output() exitFullscreen = new EventEmitter();
  @Output() enterFullscreen = new EventEmitter();

  yAxisOffset = 0;
  selectedItem!: IUnit;
  isFullScreen = false;
  isPopupOpen!: boolean;

  map!: L.Map;
  options: L.MapOptions = {
    // tap: false,
    zoom: 13,
    center: L.latLng(0, 0),
    bounceAtZoomLimits: false,
    // touchZoom: 'center',
  };
  markerClusterOptions: L.MarkerClusterGroupOptions = {
    removeOutsideVisibleBounds: true,
    animate: true,
    animateAddingMarkers: true,
    maxClusterRadius: 15,
    disableClusteringAtZoom: 15,
  };
  baseLayers: any;
  baseLayersControl: any;
  layers: L.Marker[] = [];
  popupLinkClicked = new Subject();
  isZoomEventDisabled = false;
  isInitialized = false;
  isCentrationNeeded = false;
  isChannelsActive = false;
  userLocation: L.LatLng | undefined;
  Controls = Controls;
  L = L;

  public config = inject(APP_CONFIG);
  public tabs_config = inject(TABS_CONFIG);
  public appCheck = appCheck;
  public Apps = Apps;
  public Icons!: IIcon;

  constructor(
    public deviceDetectorService: DeviceDetectorService,
    private authStorageService: AuthStorageService,
    private translate: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private zone: NgZone,
    private dialog: MatDialog,
    readonly componentElementRef: ElementRef,
  ) {
    this.matIconRegistry.addSvgIcon(
      `channels`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/images/channels.svg',
      ),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['markers'] && changes['markers'].currentValue) {
      this.setMarkers(changes['markers'].currentValue);
    }
    if (
      changes['isLassoButtonDisabled'] &&
      !_.isNull(changes['isLassoButtonDisabled'].currentValue)
    ) {
      setTimeout(() => {
        if (changes['isLassoButtonDisabled'].currentValue) {
          document
            .getElementsByClassName('leaflet-control--lasso')[0]
            .classList.add('disabled');
        } else {
          document
            .getElementsByClassName('leaflet-control--lasso')[0]
            .classList.remove('disabled');
        }
      });
    }
    if (
      changes['isSettingsTabsVisible'] &&
      !_.isNull(changes['isSettingsTabsVisible'].currentValue)
    ) {
      try {
        const center = this.map.getCenter();
        if (this.isSettingsTabsVisible) {
          this.yAxisOffset = 100;
        } else {
          this.yAxisOffset = 0;
          const px = this.map.latLngToLayerPoint(
            L.latLng(center.lat, center.lng),
          );
          const latLng = this.map.layerPointToLatLng(
            new L.Point(px.x, px.y - 100),
          );
          this.map.setView(latLng, this.map.getZoom());
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  }

  ngOnInit() {
    this.setBaseLayers();
    if (!_.isEmpty(this.markers)) {
      this.options.center = L.latLng(
        this.markers[0].latitude,
        this.markers[0].longitude,
      );
    }

    if (this.appCheck(this.config.appName, this.Apps.SMARTLIGHT)) {
      this.Icons = SmartlightAssets;
    } else if (this.appCheck(this.config.appName, this.Apps.WATERPUMPS)) {
      this.Icons = WaterpumpsAssets;
    } else {
      this.Icons = StreetlightAssets;
    }
  }

  centerMapByDefaultValues(): void {
    const latLng = L.latLng(
      this.authStorageService.getCurrentCustomer().defaultLatitude,
      this.authStorageService.getCurrentCustomer().defaultLongitude,
    );
    this.options.center = latLng;
    this.map.panTo(latLng);
  }

  getDeviceLocation(): void {
    if (this.isInitialized) {
      this.map.stopLocate();
    }
    setTimeout(() => {
      this.map.locate({ watch: true });
    }, 300);
  }

  showAlertPopup(): void {
    this.dialog.open(DialogConfirmComponent, {
      minWidth: '400px',
      data: {
        title: this.translate.instant('NOTIFICATION_POPUP.TITLE'),
        text: this.translate.instant('NOTIFICATION_POPUP.DESCRIPTION'),
        button: this.translate.instant('GLOBAL.OK'),
        class: 'accept-button center-button',
      },
    });
  }

  setBaseLayers(): void {
    const selectedMap = this.authStorageService.getCurrentUser()?.map;
    // 'Google Maps': L.tileLayer('https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
    // { detectRetina: false, maxZoom: 18, attribution: 'Google Maps' }),
    const googleMap = L.gridLayer.googleMutant({
      type: 'roadmap',
      styles: [
        {
          featureType: 'poi.business',
          stylers: [{ visibility: 'off' }],
        },
        // {
        //   featureType: 'transit',
        //   elementType: 'labels.icon',
        //   stylers: [{visibility: 'off'}],
        // },
      ],
      detectRetina: false,
      maxZoom: 18,
      attribution: 'Google Maps',
    });
    const gsm = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        detectRetina: false,
        maxZoom: 18,
        attribution: 'Open Street Map',
        opacity: 0.75,
      },
    );

    this.baseLayers = {
      'Google Maps': googleMap,
      'Open Street Map': gsm,
    };

    switch (selectedMap) {
      case 'GOOGLE_MAPS':
        this.baseLayers = {
          'Google Maps': googleMap,
          'Open Street Map': gsm,
        };
        this.options.layers = [googleMap];
        break;
      default:
        this.baseLayers = {
          'Open Street Map': gsm,
          'Google Maps': googleMap,
        };
        this.options.layers = [gsm];
        break;
    }
  }

  setMarkers(markers: IUnit[] & ITalq[]): void {
    this.clearAllMarkers();
    if (!_.isEmpty(markers)) {
      for (const item of markers) {
        this.layers.push(this.createMarker(item));
      }
    }
  }

  createMarker(item: IUnit & ITalq): L.Marker {
    if (this.appCheck(this.config.appName, this.Apps.STREETLIGHT)) {
      return L.marker([item.latitude, item.longitude], {
        id: item.id,
        icon: this.Icons.getIcon(item, this.isUnitsStatusVisible),
      }).addEventListener('click', () => {
        this.zone.run(() => this.markerClickEvent(item));
      });
    } else {
      return L.marker([item.latitude, item.longitude], {
        id: item.id,
        icon: this.Icons.getIcon(
          item,
          this.isUnitsStatusVisible,
          this.devicesType,
          this.isChannelsActive,
        ),
      })
        .bindPopup(this.createPopup(item), { closeOnClick: false })
        .on('popupopen', (event) => {
          this.isPopupOpen = true;
          event.target._popup._container
            .querySelector('.leaflet-popup-close-button')
            .addEventListener(
              'click',
              () => {
                this.isPopupOpen = false;
              },
              { once: true },
            );
          if (this.isLinksDisplayed && !this.isChannelsActive) {
            event.target._popup._container
              .querySelector('.unit-name')
              .addEventListener(
                'click',
                () => {
                  this.cancelFullscreen();
                  this.popupLinkClicked.next({
                    unit: item,
                    settingTab: this.appCheck(
                      this.config.appName,
                      this.Apps.WATERPUMPS,
                    )
                      ? this.tabs_config.fieldController
                      : this.tabs_config.founds,
                  });
                },
                { once: true },
              );
            if (
              this.devicesType === DevicesType.c2 &&
              !this.isExternalComponent
            ) {
              event.target._popup._container
                .querySelector('.unit-comments')
                .addEventListener(
                  'click',
                  () => {
                    this.cancelFullscreen();
                    this.popupLinkClicked.next({
                      unit: item,
                      settingTab:
                        item.deviceType.id === 'DUMMY'
                          ? 0
                          : this.tabs_config.comments,
                    });
                  },
                  { once: true },
                );
            }
          }
        })
        .addEventListener('click', () => this.markerClickEvent(item));
    }
  }

  createPopup(item: IUnit & ITalq): string {
    switch (this.devicesType) {
      case DevicesType.c2:
        return `${
          this.isLinksDisplayed && !this.isChannelsActive
            ? `<div class="unit-name">${item.name}</div>`
            : `<div>${item.name}</div>`
        }
          <div>${this.translate.instant('UNIT_POPUP.CONTROL_STATUSES')}: ${
            item.relayOn
          }/${item.relayOff} </div>
          <div>${this.translate.instant(
            'UNIT_POPUP.TAGS',
          )}: ${this.getTagsString(item.tags)}</div>
          ${
            this.isChannelsButtonVisible && (item as IUnit).channel
              ? `<div>${this.translate.instant('UNIT_POPUP.CHANNELS')}: ${
                  (item as IUnit).channel
                }</div>`
              : ''
          }
          ${
            this.isExternalComponent ||
            !this.isLinksDisplayed ||
            this.isChannelsActive
              ? ''
              : `<div class="unit-comments">${this.translate.instant(
                  'UNIT_POPUP.COMMENTS',
                )}</div>`
          }`;
      case DevicesType.talq:
        return item.deviceType.id === 'DEVICE'
          ? `<div class="unit-name">${item.name}</div>
          <div class="unit-popup--gray">${item.deviceClass}</div>
          <div class="unit-popup--gray">${item.physicalAddress}</div>
          <div>${this.translate.instant('UNIT_POPUP.LAMP_MODE')}: ${
            item.mode
          }</div>
           ${
             item.lampLevel
               ? `<div>${this.translate.instant('UNIT_POPUP.LAMP_LEVEL')}: ${
                   item.lampLevel
                 }</div>`
               : ''
           }
           ${
             item.calendarName
               ? `<div>${this.translate.instant('UNIT_POPUP.CALENDAR_NAME')}: ${
                   item.calendarName
                 }</div>`
               : ''
           }
           ${
             item.activeProgramName
               ? `<div>${this.translate.instant('UNIT_POPUP.PROGRAMM_NAME')}: ${
                   item.activeProgramName
                 }</div>`
               : ''
           }`
          : `<div class="unit-name">${item.name}</div>
          <div class="unit-popup--gray">${item.ipAddress}</div>
          <div class="unit-popup--gray">${item.address}</div>
          <div>${item.vendor}</div>`;
    }
  }

  clearAllMarkers(): void {
    if (!_.isEmpty(this.layers)) {
      for (const layer of this.layers) {
        this.map.removeLayer(layer);
      }
    }
    this.layers = [];
  }

  cancelFullscreen(): void {
    if (this.isFullScreen) {
      document.getElementById('fullscreen-btn')?.click();
    }
  }

  onMapReady(map: L.Map): void {
    this.map = map;
    this.setMarkers(this.markers);
    this.addControls(map);

    if (_.isEmpty(this.markers)) {
      if (!this.deviceDetectorService.isDesktop()) {
        this.isCentrationNeeded = true;
        this.getDeviceLocation();
      } else {
        this.centerMapByDefaultValues();
      }
    }

    this.isInitialized = true;
  }

  private addControls(map: L.Map): void {
    this.translateControls();
    this.baseLayersControl = L.control.layers(this.baseLayers).addTo(this.map);
    if (this.isFullscreenButtonVisible) {
      Controls.fullscreenControl.addTo(map);
      map.on('enterFullscreen', () => {
        map.invalidateSize();
        this.isFullScreen = true;
        this.enterFullscreen.emit();
      });
      map.on('exitFullscreen', () => {
        map.invalidateSize();
        this.isFullScreen = false;
        this.exitFullscreen.emit();
      });
    }

    if (this.useMarkerCluster) {
      let prevZoom = map.getZoom();
      map.on('zoomend', () => {
        if (!this.isZoomEventDisabled && this.selectedItem) {
          const currZoom = map.getZoom();
          const diff = prevZoom - currZoom;
          if (diff < 0) {
            const selectMarker =
              this.layers[
                _.findIndex(this.layers, {
                  options: { id: this.selectedItem.id },
                })
              ];
            try {
              if (!selectMarker?.isPopupOpen() && this.isPopupOpen) {
                selectMarker.closePopup();
                setTimeout(() => {
                  selectMarker.openPopup();
                }, 500);
              }
            } catch (error) {
              console.error('after zoom, openPopup error:', error);
            }
          }
          prevZoom = currZoom;
        }
      });
    }

    if (this.isRefreshButtonVisible) {
      Controls.refreshControl.addTo(map);
      map.on('refreshPressed', () => {
        this.refreshButtonClicked.emit();
      });
    }

    if (this.isChannelsButtonVisible) {
      Controls.channelsControl.addTo(map);
      map.on('channelsPressed', () => {
        this.isChannelsActive = !this.isChannelsActive;
        this.setMarkers(this.markers);
        this.channelsButtonClicked.emit();
      });
    }

    if (!this.deviceDetectorService.isDesktop()) {
      let locationMarker: L.Marker;
      let locationCircle: L.Circle;
      Controls.locationControl.addTo(map);
      map.on('locationPressed', () => {
        this.isCentrationNeeded = true;
        this.getDeviceLocation();
      });
      map
        .on('locationfound', (e) => {
          if (locationMarker && locationCircle) {
            map.removeLayer(locationMarker);
            map.removeLayer(locationCircle);
          }
          const radius = this.appCheck(
            this.config.appName,
            this.Apps.STREETLIGHT,
          )
            ? 300
            : e.accuracy / 2;
          const locationIcon = L.divIcon({
            className: 'leaflet-location-marker',
            html: '<i class="material-icons" style="font-size: 46px; color: #3f51b5;">place</i>',
            iconAnchor: [23, 40],
            popupAnchor: [0, -40],
          });
          locationMarker = L.marker(e.latlng, {
            icon: locationIcon,
          }).addTo(map);

          if (!this.appCheck(this.config.appName, this.Apps.STREETLIGHT)) {
            locationMarker.bindPopup(
              'You are within ' +
                Math.round(radius) +
                ' meters from this point',
            );
          }
          locationCircle = L.circle(e.latlng, {
            radius,
            weight: 1,
            color: '#3f51b5',
          }).addTo(map);

          if (this.isCentrationNeeded) {
            this.map.panTo(e.latlng);
            this.isCentrationNeeded = false;
          }

          this.userLocation = e.latlng;
        })
        .on('locationerror', (e) => {
          console.error(e);
          if (this.isCentrationNeeded) {
            this.centerMapByDefaultValues();
            this.isCentrationNeeded = false;
          }
        });
      // .on('zoomstart', () => {
      //   locationCircle.removeFrom(map);
      // })
      // .on('zoomend', () => {
      //   locationCircle.addTo(map);
      // });
    }

    if (this.isLassoButtonVisible) {
      const options: LassoHandlerOptions = {};
      const lasso = L.lasso(map, options);
      Controls.leafletLassoControl.addTo(map);
      map.on('lassoPressed', () => {
        lasso.enable();
      });
      map.on(
        'lasso.finished' as any,
        (event: LassoHandlerFinishedEventData | any) => {
          // TODO fix types
          this.lassoFinished(event);
        },
      );
    }
  }

  private translateControls(): void {
    Controls.fullscreenControl.options.title = this.translate.instant(
      'MAP_CONTROLS.FULLSCREEN.TITLE',
    );
    Controls.fullscreenControl.options.titleCancel = this.translate.instant(
      'MAP_CONTROLS.FULLSCREEN.TITLE_CANCEL',
    );

    Controls.refreshControl.options.title = this.translate.instant(
      'MAP_CONTROLS.REFRESH.TITLE',
    );

    Controls.channelsControl.options.title = this.translate.instant(
      'MAP_CONTROLS.CHANNELS.TITLE',
    );
    Controls.channelsControl.options.titleCancel = this.translate.instant(
      'MAP_CONTROLS.CHANNELS.TITLE_CANCEL',
    );

    Controls.locationControl.options.title = this.translate.instant(
      'MAP_CONTROLS.LOCATION.TITLE',
    );

    const zoomInButton = this.componentElementRef.nativeElement.querySelector(
      '.leaflet-control-zoom-in',
    );
    const zoomOutButton = this.componentElementRef.nativeElement.querySelector(
      '.leaflet-control-zoom-out',
    );

    if (zoomInButton) {
      zoomInButton.title = this.translate.instant('MAP_CONTROLS.ZOOM.IN');
    }
    if (zoomOutButton) {
      zoomOutButton.title = this.translate.instant('MAP_CONTROLS.ZOOM.OUT');
    }
  }

  lassoFinished(event: LassoHandlerFinishedEventData): void {
    const deviceList: (IUnit & ITalq)[] = [];
    event.layers.forEach((item) => {
      const marker =
        this.markers[
          _.findIndex(this.markers, { id: (item.options as any).id })
        ];
      if (
        marker.deviceGroupStatus !== DeviceStatus.removed &&
        marker.deviceGroupStatus !== DeviceStatus.inGroup
      ) {
        (item as L.Marker).setIcon(
          this.Icons.getIcon(
            marker,
            this.isUnitsStatusVisible,
            this.devicesType,
            false,
            true,
            this.Icons.GroupStatus.inGroup,
          ),
        );
      }
      deviceList.push(marker);
    });
    this.lassoSelectionFinished.emit(deviceList);
  }

  changeMarkerGroup(marker: IUnit, group: GroupStatus): void {
    const layer =
      this.layers[_.findIndex(this.layers, { options: { id: marker.id } })];
    layer.setIcon(
      this.Icons.getIcon(
        marker,
        this.isUnitsStatusVisible,
        this.devicesType,
        false,
        true,
        group,
      ),
    );
  }

  markerClickEvent(item: IUnit): void {
    if (!this.isLassoButtonVisible) {
      this.changeIconSize(item);
    }
    this.markerClicked.emit(item);
  }

  selectMarker(item: IUnit, zoom: boolean = false): void {
    if (!_.isUndefined(item)) {
      this.changeIconSize(item);
      const selectMarker =
        this.layers[_.findIndex(this.layers, { options: { id: item.id } })];
      setTimeout(() => {
        if (!selectMarker.isPopupOpen()) {
          selectMarker.closePopup();
          setTimeout(() => {
            selectMarker.openPopup();
          });
        }
      }, 200);
      this.moveTo(item, zoom);
    }
  }

  moveTo(item: IUnit, zoom: boolean = false): void {
    this.isZoomEventDisabled = true;
    const px = this.map.latLngToLayerPoint(
      L.latLng(item.latitude, item.longitude),
    );
    const latLng = this.map.layerPointToLatLng(
      new L.Point(px.x, px.y + this.yAxisOffset),
    );
    if (zoom) {
      this.map.setView(latLng, 15);
    } else {
      this.map.panTo(latLng);
    }
    this.isZoomEventDisabled = false;
  }

  changeIconSize(item: IUnit): void {
    if (!_.isUndefined(this.selectedItem) && item.id !== this.selectedItem.id) {
      const previousMarker =
        this.layers[
          _.findIndex(this.layers, { options: { id: this.selectedItem.id } })
        ];
      if (!_.isUndefined(previousMarker)) {
        previousMarker.setIcon(
          this.Icons.getIcon(
            this.selectedItem,
            this.isUnitsStatusVisible,
            this.devicesType,
            this.isChannelsActive,
          ),
        );
        previousMarker.closePopup();
      }
    }
    const currentMarker =
      this.layers[_.findIndex(this.layers, { options: { id: item.id } })];
    currentMarker.setIcon(
      this.Icons.getIcon(
        item,
        this.isUnitsStatusVisible,
        this.devicesType,
        this.isChannelsActive,
        true,
      ),
    );
    this.selectedItem = item;
  }

  changeYAxisOffset(): void {
    const center = this.map.getCenter();
    const px = this.map.latLngToLayerPoint(L.latLng(center.lat, center.lng));
    const latLng = this.map.layerPointToLatLng(
      new L.Point(px.x, px.y + this.yAxisOffset),
    );
    this.map.panTo(latLng);
  }

  getTagsString(tags: ITag[]): string {
    if (!tags) {
      return '';
    }

    let tagsString = '';
    for (const tag of tags) {
      tagsString += `${wrapString(tag.name, 20)}, `;
    }
    return tagsString.length > 2
      ? tagsString.substring(0, tagsString.length - 2)
      : '';
  }

  mapResize(): void {
    this.map.invalidateSize();
  }
}
