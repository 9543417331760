import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URL } from '@nx-workspace/static-data/options';

import {
  IEvent,
  ICategory,
  IDeviceType,
  IRelay,
  ISendCommand,
  IStatus,
  IUnit,
  IUnitFilters,
  IFieldController,
  IGroup,
  IHistory,
  IRequest,
  IServiceView,
  ITalq,
  IGroupDto,
  IHistoryFiltersResponse,
  IEventFiltersResponse,
} from '@nx-workspace/interfaces';

export enum DeviceStatus {
  ok = 'ok',
  alarm = 'alarm',
  acknowledged = 'acknowledged',
  maintenance = 'maintenance',
}

export enum DevicesType {
  c2 = 'c2',
  talq = 'talq',
}

@Injectable({
  providedIn: 'root',
})
export class UnitsService {
  constructor(private http: HttpClient) {}

  API_URL = inject(API_URL);

  // tslint:disable-next-line:max-line-length
  getUnits(
    page?: number,
    pageSize?: number,
    search: string = '',
    filtersObject?: IUnitFilters,
    devicesType: DevicesType = DevicesType.c2,
  ): Observable<IRequest<IUnit[]>> {
    let deviceTypes = '';
    let deviceStatuses = '';
    let filters = '';
    if (filtersObject) {
      deviceTypes = this.getSelectedDeviceTypes(filtersObject.deviceTypes);
      deviceStatuses = this.getSelectedDeviceStatuses(filtersObject.statuses);
      filters = this.getSelectedDeviceCategories(filtersObject.categories);
    }
    // return this.http.get<any>(`${this.API_URL.UNITS.GET.DEVICES}?p=${page}&s=${pageSize}`);
    // tslint:disable-next-line:max-line-length
    switch (devicesType) {
      case DevicesType.c2:
        return this.http.get<IRequest<IUnit[] & ITalq[]>>(
          `${
            this.API_URL.UNITS.GET.DEVICES
          }?p=${page}&s=${pageSize}&search=${encodeURIComponent(
            search,
          )}&deviceTypes=${deviceTypes}&deviceStatuses=${deviceStatuses}&filters=${filters}`,
        );
      case DevicesType.talq:
        return this.http.get<IRequest<IUnit[] & ITalq[]>>(
          `${
            this.API_URL.UNITS.GET.TALQ_DEVICES
          }?offset=${page}&limit=${pageSize}&search=${encodeURIComponent(
            search,
          )}&deviceTypes=${deviceTypes}&deviceStatuses=${deviceStatuses}&filters=${filters}`,
        );
    }
  }

  getSelectedDeviceTypes(deviceTypes: IDeviceType[] = []): string {
    let result = '';
    for (const deviceType of deviceTypes) {
      if (deviceType.checked) {
        if (result.length === 0) {
          result += deviceType.id;
        } else {
          result += `,${deviceType.id}`;
        }
      }
    }
    if (!result) {
      for (const deviceType of deviceTypes) {
        if (result.length === 0) {
          result += deviceType.id;
        } else {
          result += `,${deviceType.id}`;
        }
      }
    }
    return result;
  }

  getSelectedDeviceStatuses(deviceStatuses: IStatus[] = []): string {
    let result = '';
    for (const deviceStatus of deviceStatuses) {
      if (deviceStatus.checked) {
        if (result.length === 0) {
          result += deviceStatus.name;
        } else {
          result += `,${deviceStatus.name}`;
        }
      }
    }
    if (!result) {
      for (const deviceStatus of deviceStatuses) {
        if (result.length === 0) {
          result += deviceStatus.name;
        } else {
          result += `,${deviceStatus.name}`;
        }
      }
    }
    return result;
  }

  getSelectedDeviceCategories(categories: ICategory[] = []): string {
    let result = '';
    for (const category of categories) {
      delete category.checked;
      for (const tag of category.tags) {
        if (tag.checked) {
          if (!category.checked) {
            category.checked = true;
            if (result.length === 0) {
              result += `${category.id}-`;
            } else {
              result += `__${category.id}-`;
            }
          }
          if (result[result.length - 1] === '-') {
            result += tag.id;
          } else {
            result += `,${tag.id}`;
          }
        }
      }
    }
    return result;
  }

  getRelays(
    deviceId: string,
    page?: number,
    pageSize?: number,
  ): Observable<IRequest<IRelay[]>> {
    return this.http.get<IRequest<IRelay[]>>(
      this.API_URL.UNITS.GET.DEVICES + `${deviceId}/relays`,
    );
  }

  getTalqDevice(deviceAddress: string): Observable<ITalq> {
    return this.http.get<ITalq>(
      this.API_URL.UNITS.GET.TALQ_DEVICES + `${deviceAddress}`,
    );
  }

  getRelaysShort(deviceId: number): Observable<IGroup[]> {
    return this.http.get<IGroup[]>(
      this.API_URL.UNITS.GET.DEVICES + `${deviceId}/relays/short-list`,
    );
  }

  getFilters(
    devicesType: DevicesType = DevicesType.c2,
  ): Observable<IUnitFilters> {
    switch (devicesType) {
      case DevicesType.c2:
        return this.http.get<IUnitFilters>(
          this.API_URL.UNITS.GET.DEVICES + 'filters',
        );
      case DevicesType.talq:
        return this.http.get<IUnitFilters>(this.API_URL.USER.GET.TALQ_FILTERS);
    }
  }

  getEvents(
    deviceId: string,
    page?: number,
    pageSize?: number,
    startDate?: string,
    endDate?: string,
    attribute?: string,
    eventId?: string,
    baseEndPointId?: string,
  ): Observable<IRequest<IEvent[]>> {
    return this.http.get<IRequest<IEvent[]>>(
      this.API_URL.UNITS.GET.DEVICES +
        `${deviceId}/events?p=${page}&s=${pageSize}&startDate=${startDate}&endDate=${endDate}&attribute=${attribute}&eventId=${eventId}&baseEndPointId=${baseEndPointId}`,
    );
  }

  getEventsFilters(
    deviceId: string,
    startDate?: string,
    endDate?: string,
  ): Observable<IEventFiltersResponse> {
    return this.http.get<IEventFiltersResponse>(
      this.API_URL.UNITS.GET.DEVICES +
        `${deviceId}/events-filters?startDate=${startDate}&endDate=${endDate}`,
    );
  }

  getTalqEvents(
    deviceId: string,
    page?: number,
    pageSize?: number,
  ): Observable<IRequest<IEvent[]>> {
    return this.http.get<IRequest<IEvent[]>>(
      this.API_URL.UNITS.GET.TALQ +
        `events-report/${deviceId}?offset=${page}&limit=${pageSize}`,
    );
  }

  getTalqAlarms(
    deviceId: string,
    page?: number,
    pageSize?: number,
    active?: boolean,
  ): Observable<IRequest<any>> {
    return this.http.get<IRequest<any>>(
      this.API_URL.UNITS.GET.TALQ +
        `alarms-report/${deviceId}?offset=${page}&limit=${pageSize}&active=${active}`,
    );
  }

  changeTalqAlarmsStatus(
    deviceId: string,
    dto: { alarmIds: any[]; status: string },
  ): Observable<IRequest<any>> {
    return this.http.put<IRequest<any>>(
      this.API_URL.UNITS.GET.TALQ + `alarms-report/${deviceId}`,
      dto,
    );
  }

  getHistory(
    deviceId: string,
    page?: number,
    pageSize?: number,
    startDate?: string,
    endDate?: string,
    attributes?: string,
    measurementAttr?: string | null,
    endpoint?: string,
  ): Observable<IRequest<IHistory[]>> {
    return this.http.get<IRequest<IHistory[]>>(
      this.API_URL.UNITS.GET.DEVICES +
        `${deviceId}/history?p=${page}&s=${pageSize}&startDate=${startDate}&endDate=${endDate}&attributes=${attributes}&clusterAttributeId=${measurementAttr}&baseEndpointId=${endpoint}`,
    );
  }

  getHistoryFilters(
    deviceId: string,
    startDate?: string,
    endDate?: string,
  ): Observable<IHistoryFiltersResponse> {
    return this.http.get<IHistoryFiltersResponse>(
      this.API_URL.UNITS.GET.DEVICES +
        `${deviceId}/history-filters?startDate=${startDate}&endDate=${endDate}`,
    );
  }

  getFullDeviceInfo(
    deviceId: string,
    deviceType: string,
  ): Observable<IFieldController> {
    return this.http.get<IFieldController>(
      this.API_URL.UNITS.GET.DEVICES + `${deviceId}?deviceType=${deviceType}`,
    );
  }

  updateFieldController(
    deviceId: string,
    data: IFieldController,
  ): Observable<IFieldController> {
    return this.http.put<IFieldController>(
      this.API_URL.UNITS.PUT.DEVICES + `${deviceId}`,
      data,
    );
  }

  getLocalTime(deviceId: string): Observable<{ time: string }> {
    return this.http.get<{ time: string }>(
      this.API_URL.UNITS.GET.DEVICES + `${deviceId}/readtime`,
    );
  }

  getTalqLocalTime(deviceId: string): Observable<{ time: string }> {
    return this.http.get<{ time: string }>(
      this.API_URL.UNITS.GET.TALQ_DEVICES + `${deviceId}/readtime`,
    );
  }

  getTalqLampMonitor(deviceId: string): Observable<any> {
    return this.http.get<any>(
      this.API_URL.UNITS.GET.TALQ_DEVICES + `${deviceId}/functions/lampMonitor`,
    );
  }

  getTalqSensors(deviceId: string): Observable<any> {
    return this.http.get<any>(
      this.API_URL.UNITS.GET.TALQ_DEVICES + `${deviceId}/functions/sensors`,
    );
  }

  patchTalqSensors(deviceId: string, dto: any): Observable<any> {
    return this.http.patch<any>(
      this.API_URL.UNITS.GET.TALQ_DEVICES + `${deviceId}/functions`,
      dto,
    );
  }

  getTalqSensorsDescriptions(deviceId: string): Observable<any> {
    return this.http.get<any>(
      this.API_URL.UNITS.GET.TALQ_DEVICES +
        `${deviceId}/functions/descriptions`,
    );
  }

  getTalqDescriptions(deviceId: string): Observable<any> {
    return this.http.get<any>(
      this.API_URL.UNITS.GET.TALQ_DEVICES + `${deviceId}/descriptions`,
    );
  }

  updateTalqDetails(deviceId: string, dto: any): Observable<any> {
    return this.http.patch<any>(
      this.API_URL.UNITS.GET.TALQ_DEVICES + `${deviceId}`,
      dto,
    );
  }

  getGroups(): Observable<IGroup[]> {
    return this.http.get<IGroup[]>(this.API_URL.GROUPS.GET);
  }

  getUnitsByGroup(
    deviceGroups: number,
    page?: number,
    pageSize?: number,
  ): Observable<IRequest<IUnit[]>> {
    return this.http.get<IRequest<IUnit[]>>(
      // tslint:disable-next-line:max-line-length
      `${this.API_URL.UNITS.GET.DEVICES}?p=${page}&s=${pageSize}&deviceGroups=${deviceGroups}`,
    );
  }

  createGroup(dto: IGroupDto): Observable<any> {
    return this.http.post<any>(this.API_URL.GROUPS.POST, dto);
  }

  updateGroup(dto: IGroupDto, id: number): Observable<any> {
    return this.http.put<any>(this.API_URL.GROUPS.POST + id, dto);
  }

  deleteGroup(id: number): Observable<any> {
    return this.http.delete<any>(this.API_URL.GROUPS.DELETE + id);
  }

  getRelayTypes(): Observable<any> {
    return this.http.get<any>(this.API_URL.UNITS.GET.RELAY_TYPES);
  }

  getDevicesByRelayType(relayType: string): Observable<IUnit[]> {
    return this.http.get<IUnit[]>(
      `${this.API_URL.UNITS.GET.DEVICES_SHORT}?endPointType=${relayType}`,
    );
  }

  getDaliList(id: number): Observable<any> {
    return this.http.get<any>(this.API_URL.UNITS.GET.DALI_LIST(id));
  }

  getDali(deviceId: number, daliId: number): Observable<any> {
    return this.http.get<any>(this.API_URL.UNITS.GET.DALI(deviceId, daliId));
  }

  sendCommands(command: ISendCommand): Observable<any> {
    return this.http.post<any>(this.API_URL.UNITS.POST.ADD_COMMAND, command);
  }

  getServiceView(deviceId: number): Observable<IServiceView> {
    return this.http.get<any>(this.API_URL.UNITS.GET.SERVICE_VIEW(deviceId));
  }

  updateServiceView(deviceId: number): Observable<IServiceView> {
    return this.http.post<any>(
      this.API_URL.UNITS.GET.SERVICE_VIEW(deviceId),
      {},
    );
  }
}
