import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-welcome-dialog-view',
  templateUrl: './welcome-dialog-view.component.html',
  styleUrls: ['./welcome-dialog-view.component.scss'],
  standalone: false,
})
export class WelcomeDialogViewComponent {
  constructor(public deviceDetectorService: DeviceDetectorService) {}
}
