import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as CryptoJS from 'crypto-js';

import { ICustomer, IUser, IAvatar } from '@nx-workspace/interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthStorageService {
  private readonly authDataKey: string = 'auth-data';
  private readonly secretKey: string = 'vopnuG-gurvy4-qossis';
  authData$: BehaviorSubject<IUser | null> = new BehaviorSubject<IUser | null>(
    null,
  );

  constructor() {}

  getAuthData(): IUser | undefined {
    const storedData = localStorage.getItem(this.authDataKey);
    if (!storedData) {
      return;
    }
    try {
      const decryptedData = CryptoJS.AES.decrypt(
        storedData,
        this.secretKey,
      ).toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedData);
    } catch {
      return;
    }
  }

  setAuthData(authData: IUser): void {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(authData),
      this.secretKey,
    ).toString();
    localStorage.setItem(this.authDataKey, encryptedData);
    this.authData$.next(authData);
  }

  clearAllData(): void {
    this.eraseAuthData();
  }

  private eraseAuthData(): void {
    localStorage.removeItem(this.authDataKey);
    this.authData$.next(null);
  }

  getCurrentUser(): IUser {
    return this.getAuthData();
  }

  getCurrentId(): number | null {
    const data: IUser = this.getAuthData();
    return data ? data.id : null;
  }

  getCurrentRole(): any {
    const data: IUser = this.getAuthData();
    return data ? data.userRole : null;
  }

  isAnyAdmin(): boolean {
    return this.isRootRole() || this.isAdminRole();
  }

  isRootRole(): boolean {
    return this.getCurrentRole() === 'ROLE_ROOT';
  }

  isAdminRole(): boolean {
    return this.getCurrentRole() === 'ROLE_CUSTOMER_ADMIN';
  }

  isExternalUser(): boolean {
    return this.getCurrentRole() === 'ROLE_EXTERNAL_USER';
  }

  isViewerRole(): boolean {
    return this.getCurrentRole() === 'ROLE_VIEW';
  }

  isServiceTechnicianRole(): boolean {
    return this.getCurrentRole() === 'servicetechnician';
  }

  isEngineerRole(): boolean {
    return this.getCurrentRole() === 'CONTROL_ENGINEER';
  }

  hasAccessTalq(): boolean {
    const data: IUser = this.getAuthData();
    return !!data.currentCustomer?.allowTalq;
  }

  getCurrentCustomer(): ICustomer | null | undefined {
    const data: IUser = this.getAuthData();
    return data ? data.currentCustomer : null;
  }

  getCurrentAvatar(): IAvatar | null {
    const data: IUser = this.getAuthData();
    return data ? data.avatar : null;
  }
}
