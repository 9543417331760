<mat-progress-bar
  *ngIf="isVisible && isMinified"
  mode="indeterminate"
></mat-progress-bar>
<div class="preloader" *ngIf="isVisible && !isMinified">
  <mat-spinner></mat-spinner>
  <button
    *ngIf="showCancelButton"
    class="preloader-button"
    mat-flat-button
    (click)="hideSpinner()"
  >
    {{ 'GLOBAL.CANCEL' | translate }}
  </button>
</div>
