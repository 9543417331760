import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { temperatureColor } from '@nx-workspace/static-data/streetlight/map-assets';
import { RxStompService } from '../../../rx-stomp.service';
import { IUnit } from '../../../interfaces';
import { PreloaderService } from '../../../services/preloader.service';
import { UnitsService } from '../../../services/units.service';

enum PopupState {
  main,
  captcha,
  success,
  error,
  survey,
}
enum SurveyState {
  main,
  survey,
  finish,
}

enum SurveyChoice {
  never,
  later,
  rate,
}
@Component({
  selector: 'app-popup-basic',
  templateUrl: './popup-basic.component.html',
  styleUrls: ['./popup-basic.component.scss'],
  standalone: false,
})
export class PopupBasicComponent {
  private unsubscribe = new Subject();
  popupState = PopupState;
  currentState = PopupState.main;
  surveyState = SurveyState;
  currentSurveyState = SurveyState.main;
  surveyChoice = SurveyChoice;
  rating: number | undefined;

  selectedColor = '';
  applyColor = '';
  surveyTextArea = '';
  isSurveySending = false;

  constructor(
    private http: HttpClient,
    private preloaderService: PreloaderService,
    private unitsService: UnitsService,
    private rxStompService: RxStompService,
    public dialogRef: MatDialogRef<PopupBasicComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { device: IUnit },
  ) {
    this.selectedColor = data.device.color;
  }

  onSelectedColor(color: string): void {
    this.selectedColor = color;
  }

  onApplyColor(): void {
    this.currentState = this.popupState.captcha;
    this.applyColor = this.selectedColor;
  }

  onCaptchaTrue(token: string): void {
    if (token) {
      this.preloaderService.show();
      const color = this.selectedColor.slice(1);
      const device = {
        ...this.data.device,
        color: color,
      };

      if (!this.isRgb(this.data.device)) {
        switch (this.selectedColor) {
          case temperatureColor.warm:
            device.color = '1';
            break;
          case temperatureColor.neutral:
            device.color = '2';
            break;
          case temperatureColor.cold:
            device.color = '3';
            break;
        }
      }
      // console.log(device);
      const receipt_id = new Date().getTime().toString();
      this.rxStompService.watchForReceipt(receipt_id, () => {
        // console.log('Receipt: ', frame);
        this.currentState = this.popupState.success;
        this.preloaderService.hide();
      });
      this.rxStompService.publish({
        destination: '/ws/devices/' + device.id,
        body: JSON.stringify(device),
        headers: { receipt: receipt_id },
      });

      // this.currentState = this.popupState.success;
      // this.rxStompService.publish({ destination: '/topic/devices/' + device.id, body: JSON.stringify(device) });
      // this.unitsService.updateColor({
      //   ...this.data.device,
      //   color: color
      // })
      // .pipe(takeUntil(this.unsubscribe))
      // .subscribe(() => {},
      //   (httpAnswer) => {
      //     if (httpAnswer.status == 200) {
      //       this.currentState = this.popupState.success;
      //     } else {
      //       this.currentState = this.popupState.error;
      //     }
      //     this.preloaderService.hide();
      //   }
      // )
    }
  }

  doneButtonClicked(): void {
    const survey = localStorage.getItem('survey');
    if (survey) {
      const timeToShowSurvey =
        new Date().getTime() - new Date(JSON.parse(survey).date).getTime() >
        1000 * 60 * 60 * 24;
      if (JSON.parse(survey).val === SurveyChoice.later && timeToShowSurvey) {
        this.currentState = this.popupState.survey;
      } else {
        this.dialogRef.close(this.selectedColor);
      }
    } else {
      this.currentState = this.popupState.survey;
    }
  }

  isRgb(item: IUnit): boolean {
    return item.deviceType === 0;
  }

  getColor(item: IUnit): string {
    if (item.deviceType === 1) {
      switch (item.color) {
        case '#1':
          return temperatureColor.warm;
        case '#2':
          return temperatureColor.neutral;
        case '#3':
          return temperatureColor.cold;
      }
    }
    return item.color;
  }

  survey(val: SurveyChoice): void {
    if (val === SurveyChoice.rate) {
      this.currentSurveyState = this.surveyState.survey;
    } else {
      localStorage.setItem('survey', JSON.stringify({ val, date: new Date() }));
      this.dialogRef.close(this.selectedColor);
    }
  }

  submitSurvey(): void {
    const url =
      'https://script.google.com/macros/s/AKfycbxG0RURYvcsdHJf_YK6_3LJx9PdRoXaM1wNLOp4PUdmviVSDfc5NaammrgOLVaFjpqerg/exec?';
    const data = {
      Rating: this.rating as number,
      Feedback: this.surveyTextArea,
      'Device Id': this.data.device.id,
      'Device Color': this.selectedColor,
      UserAgent: navigator.userAgent,
    };
    if (!this.isRgb(this.data.device)) {
      switch (this.selectedColor) {
        case temperatureColor.warm:
          data['Device Color'] = 'Warm';
          break;
        case temperatureColor.neutral:
          data['Device Color'] = 'Neutral';
          break;
        case temperatureColor.cold:
          data['Device Color'] = 'Cold';
          break;
      }
    }

    this.preloaderService.show();
    this.http
      .get(url + new HttpParams({ fromObject: data }).toString(), {})
      .subscribe(
        () => {
          this.preloaderService.hide();
          localStorage.setItem(
            'survey',
            JSON.stringify({ val: SurveyChoice.rate, date: new Date() }),
          );
          this.dialogRef.close(this.selectedColor);
        },
        () => {
          this.preloaderService.hide();
        },
      );
  }
}
