import { environment } from '../environments/environment';

export const ENVIRONMENT = environment.baseUrl;
export const BASE_API_URL = `${ENVIRONMENT}/api/v1`;
export const PAGE_SIZE_OPTIONS = [30, 50, 100];
export const FILE_SIZE_MAX = 52428800;
export const MIN_DATE = new Date(2000, 0, 1);
export const API_URL = {
  UNITS: {
    GET: {
      AUTOMATIC_MODE: `${BASE_API_URL}`,
      DEVICES: `${BASE_API_URL}/devices/`,
    },
    PUT: {
      DEVICES: `${BASE_API_URL}/devices/`,
      DEVICE_COMMANDS_SETTINGS_MODE: (deviceId: string | number) =>
        `${BASE_API_URL}/devices/${deviceId}/mode`,
      DEVICE_COMMANDS_SETTINGS_MODE_GROUP: (
        deviceTypes: string = '',
        filters: string = '',
        deviceStatuses: string = '',
        search: string = '',
      ) =>
        `${BASE_API_URL}/devices/group?deviceType=${deviceTypes}&filter=${filters}&search=${encodeURIComponent(
          search,
        )}&deviceStatuses=${deviceStatuses}`,
      DEVICE_COMMANDS_SETTINGS: (deviceId: string | number, type: string) =>
        `${BASE_API_URL}/devices/${deviceId}/modeSettings?type=${type}`,
      DEVICE_GROUP_MODE: (
        type: string,
        deviceTypes: string = '',
        filters: string = '',
        deviceStatuses: string = '',
        search: string = '',
      ) =>
        `${BASE_API_URL}/devices/groupMode?type=${type}&deviceType=${deviceTypes}&filter=${filters}&search=${encodeURIComponent(
          search,
        )}&deviceStatuses=${deviceStatuses}`,
    },
    PATCH: {
      COMMANDS_REFRESH: (deviceId: string | number, relayId: string | number) =>
        `${BASE_API_URL}/devices/${deviceId}/${relayId}/refresh`,
    },
    POST: {
      ADD_COMMAND: `${BASE_API_URL}/talq/devices/commands/`,
    },
  },
};
