import { InjectionToken } from '@angular/core';

export interface IAppConfig {
  baseUrl: string;
  appName: string;
}

export const APP_CONFIG = new InjectionToken<IAppConfig>('Application.Config');

export const API_URL = new InjectionToken<any>('API_URL_TOKEN');

export const TABS_CONFIG = new InjectionToken<any>('');

export const SIDEMENU_CONFIG = new InjectionToken<any>('');
